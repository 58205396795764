import { programHeader } from './header/program-header.js';
import './program.scss';

let showAll = true;

export function program() {
  programHeader();

  $(function () {
    initializeSaleDate();
    initializeDaysList();
  });
}

function initializeSaleDate() {
  displaySaleDate();

  $('#program_status').change(function () {
    displaySaleDate();
  });
}

function displaySaleDate() {
  const programStatus = parseInt($('#program_status').val());
  const saleDate = $('#program_saleDate');
  const soldStatusList = saleDate.data('sold-status-list');
  const saleDateRow = saleDate.closest('.form-group');

  if (soldStatusList && soldStatusList.indexOf(programStatus) == -1) {
    saleDateRow.addClass('hidden');
    saleDate.val(null);
    saleDate.removeAttr('required');
  } else {
    // Prefill with today date by simulating a user click on the calendar
    if (!saleDate.val()) {
      const calendarIcon = saleDate.prev();
      calendarIcon.click();

      const today = $('.bootstrap-datetimepicker-widget .day.today');
      if (today.length > 0) {
        today.click();
      } else {
        calendarIcon.click();
      }
    }
    saleDate.attr('required', 'required');
    saleDateRow.removeClass('hidden');
  }
}

function initializeDaysList() {
  $('.app-zoom-form').on('click', '.program-day', function () {
    $(this).next().toggleClass('program-activities-hidden');
    $(this).find('i').toggleClass('glyphicon-plus glyphicon-minus');

    updateShowAll();
  });

  $('.app-zoom-form').on('click', '.program-th-day', function () {
    let activities = $('.program-activities');

    if (showAll) {
      activities.removeClass('program-activities-hidden');
      $('.program-day i')
        .addClass('glyphicon-minus')
        .removeClass('glyphicon-plus');
    } else {
      activities.addClass('program-activities-hidden');
      $('.program-day i')
        .addClass('glyphicon-plus')
        .removeClass('glyphicon-minus');
    }

    updateShowAll();
  });
}

function updateShowAll() {
  showAll =
    $('.program-activities').not('.program-activities-hidden').length == 0;

  if (showAll) {
    $('.program-th-day i')
      .addClass('glyphicon-plus')
      .removeClass('glyphicon-minus');
  } else {
    $('.program-th-day i')
      .addClass('glyphicon-minus')
      .removeClass('glyphicon-plus');
  }
}
