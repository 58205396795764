import router from 'tools/router';
import dialog from 'tools/dialog';
import { initializeFormFields } from 'table/form';

import { loadGrids } from '../images/images.js';

import './day.scss';

let day = 'day';

export function dayActivities() {
  $(function () {
    initializeDayFormId();
    initializeActivityForms();
    initializeAddButtons();
  });
}

function initializeDayFormId() {
  if ($('#day_template').length > 0) {
    day = 'day_template';
  }
}

function initializeActivityForms() {
  $('#' + day + '_activities input[id$=_type]').each(function () {
    const activityId = $(this).attr('id').slice(day.length).split('_')[2];
    cleanActivityForm(activityId);

    $('#' + day + '_activities').append(
      '<div id="well_' + activityId + '" class="well"></div>'
    );
    $('#well_' + activityId).append(
      $('#' + day + '_activities_' + activityId + '_type')
        .parent()
        .parent()
        .parent()
    );
  });

  initializeOptionalActivities();
}

function initializeOptionalActivities() {
  $('form[name="' + day + '"] input[id$=_optional]').each(function () {
    displayOptionalRemark($(this));
  });

  $('form[name="' + day + '"]').on(
    'change',
    'input[id$=_optional]',
    function () {
      displayOptionalRemark($(this));
    }
  );
}

function displayOptionalRemark(input) {
  const sectionId = input.attr('id') + 'Section';
  const remarkId = input.attr('id') + 'Remark';

  if (input.is(':checked')) {
    $('#' + sectionId).removeClass('hidden');
    $('#' + remarkId)
      .closest('.form-group')
      .removeClass('hidden');
  } else {
    $('#' + sectionId).addClass('hidden');
    $('#' + remarkId)
      .closest('.form-group')
      .addClass('hidden');
  }
}

function getMaxOrder() {
  let maxOrder = 0;

  $('input[id$="_activityOrder"]').each(function () {
    const order = parseInt($(this).val());
    maxOrder = order > maxOrder ? order : maxOrder;
  });

  return Math.floor(maxOrder + 1);
}

function cleanActivityForm(activityId, order = null) {
  const formId = day + '_activities_' + activityId;

  const activityOrder = $('#' + formId + '_activityOrder');
  if (activityOrder.val() == '') {
    activityOrder.val(order);
  }

  const label =
    '<span class="col-sm-12  col-sx-4">' +
    $('#AddToDay_' + $('#' + formId + '_type').val()).data('name') +
    '</span><button id="DeleteActivity_' +
    activityId +
    '" class="col-sm-12 col-sx-8 btn btn-danger glyphicon glyphicon-trash tp-planner-activity-delete"></button>';
  $('#' + formId)
    .closest('.form-group')
    .children('label')
    .html(label)
    .addClass('no-after');

  $('#DeleteActivity_' + activityId).click(function () {
    $('#' + formId)
      .closest('.form-group')
      .parent()
      .remove();
  });

  displayOptionalRemark($('#' + formId + '_optional'));

  return formId;
}

function addNewActivity(
  programId,
  actionLabel,
  activityType,
  prefillService = null
) {
  dialog.loading(actionLabel);

  const routingParams = {
    program: programId,
    type: activityType,
  };
  if (prefillService) {
    routingParams.service = prefillService;
  }

  $.post(
    //URL
    router.generate(
      'tp_planner_program_confirmAddActivity_AJAX',
      routingParams
    ),
    // Success
    function (data) {
      const labelIndex =
        'N°' + ($('#' + day + '_activities').children().length + 1);
      const index =
        $('#' + day + '_activities').children().length + '_' + Date.now();
      const prototype = data
        .replace(
          /name="day(_template)?_activities___name__/g,
          'name="' + day + '[activities][__name__]'
        )
        .replace(/day_activities/g, day + '_activities')
        .replace(/__name__label__/g, labelIndex)
        .replace(/__name__/g, index);

      const prefix = day + '_activities_' + index;
      $('#' + day + '_activities').append(
        $('<div>').addClass('well hidden').html(prototype)
      );

      $('#' + prefix + '_type').val(activityType);
      cleanActivityForm(index, getMaxOrder());

      initializeFormFields('#' + prefix);
      loadGrids('#' + prefix);

      $('#' + day + '_activities')
        .children()
        .removeClass('hidden');
      dialog.close();
    }
  );
}

function displayActivitySelectionForm(
  programId,
  actionLabel,
  activityType,
  country,
  city
) {
  dialog.loading(actionLabel);

  const parameters = { program: programId, type: activityType };
  if (typeof country !== 'undefined') parameters.country = country;
  if (typeof city !== 'undefined') parameters.city = city;

  $.post(
    //URL
    router.generate('tp_planner_program_selectActivity_AJAX', parameters),

    // Success
    function (form) {
      dialog.open(actionLabel, form);
      $(dialog.DIALOG_ID + ' .col-sm-2')
        .removeClass('col-sm-2')
        .addClass('col-sm-4');
      $(dialog.DIALOG_ID + ' .col-sm-10')
        .removeClass('col-sm-10')
        .addClass('col-sm-8');

      $('#select_activity_country, #select_activity_city').change(function () {
        const countryVal = $('#select_activity_country').val();
        if (countryVal == '') {
          displayActivitySelectionForm(programId, actionLabel, activityType);
        } else {
          const cityVal = $('#select_activity_city').val();
          if (cityVal == '' || countryVal != country) {
            displayActivitySelectionForm(
              programId,
              actionLabel,
              activityType,
              countryVal,
              0
            );
          } else {
            displayActivitySelectionForm(
              programId,
              actionLabel,
              activityType,
              countryVal,
              cityVal
            );
          }
        }
      });

      if (!$('#select_activity_service').val()) {
        $('#AddActivityConfirmation').addClass('disabled');
      }

      $('#select_activity_service').change(function () {
        if ($('#select_activity_service').val()) {
          $('#AddActivityConfirmation').removeClass('disabled');
        } else {
          $('#AddActivityConfirmation').addClass('disabled');
        }
      });

      $('#AddEmptyActivityConfirmation').click(function () {
        addNewActivity(programId, actionLabel, activityType);
      });
      $('#AddActivityConfirmation').click(function () {
        let prefillService = $('#select_activity_service').val();

        if (prefillService) {
          addNewActivity(programId, actionLabel, activityType, prefillService);
        }
      });
      $('#AddActivityCancel').click(function () {
        dialog.close();
      });
    }
  );
}

function initializeAddButtons() {
  $('#AddActivityToDayButtons a').click(function () {
    let programId = $(this).parent().data('program-id');
    if (programId == 0) {
      programId = parseInt($('#' + day + '_program').val());
      $(this).parent().data('program-id', programId);
    }

    const actionLabel = $(this).text();
    const activityType = $(this).data('type');

    displayActivitySelectionForm(programId, actionLabel, activityType);
  });
}
