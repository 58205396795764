import { initializeFormFields, displaySubformAsArray } from 'table/form';

let cotation = 'cotation';

export function cotationBlocs() {
  $(function () {
    initialize();
  });
}

function initialize() {
  if ($('#cotation_template').length > 0) {
    cotation = 'cotation_template';
  }

  $('#' + cotation + '_blocs > div.form-group > div > div').each(function (
    index
  ) {
    let blocId = $(this).attr('id').split('_').pop();
    let container = $(this).parent().parent();

    container.children('label').text('N°' + (index + 1));
    container.children('label').addClass('col-sm-1').removeClass('col-sm-2');
    container.children('div').addClass('col-sm-11').removeClass('col-sm-10');
    $('#' + cotation + '_blocs').append(
      $('<div>').addClass('well').append(container)
    );

    cleanBlocForm(blocId);
  });

  initializeAddButtons();
}

function addNewBloc() {
  let container = $('#' + cotation + '_blocs');
  let labelIndex = 'N°' + (container.children().length + 1);
  let index = container.children().length + '_' + Date.now();
  let prototype = container
    .attr('data-prototype')
    .replace(/cotationLines___name__/g, 'µCLNµ') // Subform in subform protection
    .replace(/\[cotationLines\]\[__name__\]/g, '£CLN£') // Subform in subform protection
    .replace(/__name__label__/g, labelIndex)
    .replace(/__name__/g, index)
    .replace(/µCLNµ/g, 'cotationLines___name__') // Subform in subform protection
    .replace(/£CLN£/g, '[cotationLines][__name__]'); // Subform in subform protection

  prototype = $('<div>').addClass('well').html(prototype);
  prototype
    .children()
    .children('label')
    .addClass('col-sm-1')
    .removeClass('col-sm-2');
  prototype
    .children()
    .children('div')
    .addClass('col-sm-11')
    .removeClass('col-sm-10');

  container.append($(prototype).addClass('hidden'));

  cleanBlocForm(index);
  initializeFormFields('#' + cotation + '_blocs_' + index);

  // Cotation Lines
  displaySubformAsArray('#' + cotation + '_blocs_' + index);

  container.children().removeClass('hidden');
}

function cleanBlocForm(blocId) {
  let formId = '' + cotation + '_blocs_' + blocId;
  let label = $('#' + formId)
    .closest('.form-group')
    .children('label');

  let labelName = $('<span>')
    .addClass('col-sm-12  col-sx-4')
    .html(label.text());
  let deleteButton = $('<button>')
    .attr('id', 'DeleteBloc_' + blocId)
    .addClass(
      'col-sm-12 col-sx-8 btn btn-danger glyphicon glyphicon-trash tp-table-subform-section-delete'
    );
  label
    .addClass('no-after tp-table-subform-section-label')
    .html(labelName)
    .append(deleteButton);

  $('#DeleteBloc_' + blocId).click(function () {
    $('#' + formId)
      .closest('.form-group')
      .parent()
      .remove();
  });

  return formId;
}

function initializeAddButtons() {
  $('#AddBlocToCotationButton a').click(function () {
    addNewBloc();
  });
}
