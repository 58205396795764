import dialog from 'tools/dialog';
import { displayImageGridInForm } from 'table/image-grid';

import './image.scss';

export function images() {
  $(function () {
    prefillName();
    loadGrids();
  });
}

function prefillName() {
  $('.table-form-row,' + dialog.DIALOG_ID).on(
    'change',
    '#image_imageFile_file',
    function () {
      var filePath = $('#image_imageFile_file').val().split('\\');
      var fileName = filePath[filePath.length - 1].split('.')[0];
      $('#image_name').val(fileName);
    }
  );
}

export function loadGrids(container = '') {
  $(container + ' .tp-table-images-grid-container').each(function () {
    let gridId = $(this).attr('id');
    let forcedFilter = $(this).data('filter');
    let objectRootId = '#' + gridId.substring(16, gridId.length - 6);

    let filter = function () {
      if (forcedFilter) {
        return forcedFilter;
      } else {
        let countries = $(objectRootId + 'countries').val();
        let cities = $(objectRootId + 'cities').val();

        let filter = {};
        if (countries && countries.length > 0) {
          filter.country = countries;
        }
        if (cities && cities.length > 0) {
          filter.city = cities;
        }

        return filter;
      }
    };

    displayImageGridInForm('#' + gridId, filter);
  });
}
