import dialog from 'tools/dialog';
import router from 'tools/router';

import './markdown-helper.scss';

export function activateMarkdownHelper(container = '') {
  $(container + ' .tp-table-markdown span').click(function () {
    const dialogLabel = $(this).attr('title');
    dialog.loading(dialogLabel);

    $.post(
      //URL
      router.generate('app_table_helper_markdown_AJAX'),

      // Success
      function (helper) {
        dialog.open(dialogLabel, helper, 1200, true);
      }
    );
  });

  $(dialog.DIALOG_ID).on('click', '.tp-table-markdown-doc', function (e) {
    e.preventDefault();
    window.open($(this).data('url'));
  });
}
