import 'datatables.net-bs';
import 'datatables.net-bs/css/dataTables.bootstrap.css';

import 'datatables.net-plugins/sorting/intl.js';
import datatables_fr from 'datatables.net-plugins/i18n/fr-FR.json';

import './datatables.scss';

export default {
  initialize: initialize,
  display: display,
};

function initialize() {
  $(function () {
    // Order with Accents
    $.fn.dataTable.ext.order.intl();

    // Support French Language
    if ($('html').attr('lang') == 'fr') {
      $.extend(true, $.fn.dataTable.defaults, {
        language: datatables_fr,
      });
    }
  });
}

function display(
  mode = 'list',
  container = '.tp-table-list',
  customOptions = null
) {
  $(container + ' .tp-table-datatable').each(function () {
    const defaultOptions = getDefaultOptions($(this), mode);
    const options = !customOptions
      ? defaultOptions
      : Object.assign(defaultOptions, customOptions);
    if ($(this).data('persist-state')) {
      options.stateSave = true;
      options.stateDuration = -1;
    }

    const datatable = $(this).DataTable(options);
    $(this).parent().addClass('tp-table-datatable-parent');
    $(this).removeClass('hidden');
    datatable.columns.adjust().draw();
  });
}

function getDefaultOptions(table, mode) {
  const columnsProp = table.data('columnsprop');
  let notOrderable;
  let order;

  switch (mode) {
    case 'list':
      notOrderable = columnsProp.nbFields;
      order = columnsProp.order;
      break;
    case 'popup':
      notOrderable = 0;
      order = columnsProp.order.map(function (x) {
        x[0] = x[0] + 1;
        return x;
      });
      break;
    default:
      throw 'Unknown mode ("list" and "popup" allowed) : ' + mode;
  }

  const options = {
    columnDefs: [{ orderable: false, targets: notOrderable }],
    order: order,
  };

  let dateOrderPosition = columnsProp.nbFields + 1;
  $.each(columnsProp.dateFields, function (position) {
    options.columnDefs.push({
      orderData: dateOrderPosition,
      targets: getActualPosition(mode, position),
    });
    options.columnDefs.push({
      visible: false,
      searchable: false,
      targets: dateOrderPosition,
    });

    dateOrderPosition++;
  });

  $.each(columnsProp.hiddenFields, function (position) {
    options.columnDefs.push({
      visible: false,
      targets: getActualPosition(mode, position),
    });
  });

  return options;
}

function getActualPosition(mode, position) {
  return mode == 'list' ? parseInt(position) : parseInt(position) + 1;
}
