import display from './display.js';
import { JSONParse } from 'tools/utilities.js';

let configuration;

export default {
  removeEmptySelectableElements,
  addClickEvents,
  scrollToClickedElement,
};

// API
function removeEmptySelectableElements() {
  $('.program-print-config-break-selectable').each(function () {
    if ($(this).height() === 0) $(this).remove();
  });
}

function addClickEvents() {
  configuration = getPrintConfigurationInForm();

  $(
    '.program-print-config-break-selectable .program-print-config-break-action'
  ).click(function () {
    updateDisplay($(this), addPageBreak);
  });

  $(
    '.program-print-config-break-selected .program-print-config-break-action'
  ).click(function () {
    updateDisplay($(this), removePageBreak);
  });

  $(
    '.program-print-config-hide-selectable .program-print-config-hide-action'
  ).click(function () {
    updateDisplay($(this), hideElement);
  });

  $(
    '.program-print-config-hide-selected .program-print-config-hide-action'
  ).click(function () {
    updateDisplay($(this), displayElement);
  });
}

function scrollToClickedElement() {
  const url = new URL(window.location.href);
  const clicked = url.searchParams.get('clicked');

  if (clicked) {
    // Seems like the browser tries to apply the scroll again after reload and a setTimeout doesn't do the trick
    // Resetting to scroll 0,0 even without a setTimeout is working for some reason...
    window.scrollTo(0, 0);
    window.scrollTo(0, $('#' + clicked).offset().top - 100);
  }
}

// Actions
function updateDisplay(element, action) {
  display.hideDocument();

  const elementId = getElementId(element);
  action(elementId);
  submitForm(elementId);
}

function addPageBreak(elementId) {
  configuration.break.push(elementId);
}

function removePageBreak(elementId) {
  configuration.break = configuration.break.filter((pb) => pb !== elementId);
}

function hideElement(elementId) {
  configuration.hidden.push(elementId);
  removePageBreak(elementId);
}

function displayElement(elementId) {
  configuration.hidden = configuration.hidden.filter((pb) => pb !== elementId);
}

// Form
function getPrintConfigurationInForm() {
  const configuration = $('#print_configuration_printConfiguration').val();
  return JSONParse(configuration, { break: [], hidden: [] });
}

function submitForm(elementId) {
  $('#print_configuration_printConfiguration').val(
    JSON.stringify(configuration)
  );

  const cleanUrl = window.location.href.replace(/[#,?].*$/, '');
  const submitUrl = `${cleanUrl}?clicked=${elementId}`;
  $('form[name="print_configuration"]').attr('action', submitUrl).submit();
}

// Tools
function getElementId(element) {
  // split method in order to take into account splittable elements (only the first part of the ID is relevant here)
  return element.parent().parent().attr('id').split('-')[0];
}
