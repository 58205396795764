import 'jquery-ui/dialog';
import 'jquery-ui-css/all.css';
import 'jquery-ui-touch-punch';

import './dialog.scss';

let DIALOG_ID = '#TP_Table_Dialog';

export default {
  DIALOG_ID,
  initialize,
  create,
  open,
  close,
  center,
  loading,
};

function initialize() {
  $(function () {
    create(DIALOG_ID);
  });
}

function create(containerId) {
  let container = $('<div>').attr('id', containerId.substr(1));
  $('body').append(container);
}

function open(
  actionLabel,
  content,
  minWidth = 500,
  closeButton = false,
  closeClosure = undefined,
  containerId = DIALOG_ID
) {
  if (typeof content !== 'undefined') $(containerId).html(content);

  $(containerId).dialog({
    dialogClass: 'core-dialog',
    title: actionLabel,

    resizable: false,
    minWidth: minWidth,
    height: 'auto',
    maxHeight: $(window).height(),

    show: {
      effect: 'blind',
      duration: 200,
    },
    hide: {
      effect: 'blind',
      duration: 200,
    },

    modal: true,
  });

  if (closeButton) {
    let closeButton = $('<span>').addClass(
      'glyphicon glyphicon-remove tp-core-dialog-close'
    );
    $(containerId).prev().find('.ui-dialog-title').append(closeButton);

    if (closeClosure === undefined) {
      closeClosure = function () {
        close(containerId);
      };
    }
    $(containerId)
      .prev()
      .find('.ui-dialog-title .tp-core-dialog-close')
      .click(closeClosure);
  }

  // Trick to center & size the popup on the server (not localhost, don't know why it doens't center & size properly in this case...)
  setTimeout(function () {
    center(containerId);
  }, 0);
}

function close(containerId = DIALOG_ID) {
  if ($(containerId).hasClass('ui-dialog-content')) {
    $(containerId).dialog('close');
  }
}

function center(containerId = DIALOG_ID) {
  if ($(containerId).dialog('isOpen')) {
    $(containerId).dialog({
      height: 'auto',
      maxHeight: $(window).height(),
      position: { my: 'center', at: 'center', of: window },
    });
  }
}

function loading(actionLabel = '', containerId = DIALOG_ID) {
  var content = $('<div>').addClass('loading');
  open(actionLabel, content, 500, false, undefined, containerId);
}
