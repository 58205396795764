import display from 'features/tripplanner/print/js/display.js';
import { splitSheets } from 'features/tripplanner/print/js/split-sheets.js';

import 'paper-css/paper.css';
import 'raleway-webfont/raleway.min.css';

import 'features/tripplanner/print/css/print.scss';
import './documentation.scss';
import './images/screenshots.js';

export function documentation() {
  if ($('body#app-documentation-print').length > 0) {
    // Wait for the whole page to be loaded (especialy images) before making treatements
    $(window).on('load', function () {
      // Treatments
      tableOfContents();
      splitSheets();

      // Display the document
      display.printButton();
      display.displayDocument();
    });
  }
}

function tableOfContents() {
  const titlesDOM = ['h1', 'h2', 'h3', 'h4', 'h5'];
  let titles = {};

  $(titlesDOM.join(',')).each(function (index) {
    if (index > 0) {
      const scroll = parseInt($(this).offset().top);
      titles[scroll] = {
        dom: this.tagName.toLowerCase(),
        text: $(this).text(),
      };
    }
  });

  for (const scroll in titles) {
    $('#app-table-of-contents ul').append(
      $('<li>')
        .addClass('tp-core-title-' + titles[scroll].dom)
        .text(titles[scroll].text)
    );
  }
}
