const hierarchy = ['/print/', '/degradedPrint/', '/mostDegradedPrint/'];

export function manageRiskyImages() {
  const images = $('img.tp-planner-risky-image');

  // Try to catch the error event
  images.on('error', function () {
    manageError($(this));
  });

  // Look for data-loaded attribute at initialisation in case the error occured before the event was set
  images.filter('[data-loaded=false]').each(function () {
    manageError($(this));
  });
}

function manageError(img) {
  getLowerDefinition(img);
  if (isBackGroundImage(img)) {
    adaptBackground(img.attr('src'));
  }
}

function getLowerDefinition(img) {
  const src = img.attr('src');

  for (let i = 0; i < hierarchy.length - 1; i++) {
    if (src.search(hierarchy[i]) > -1) {
      img.attr('src', src.replace(hierarchy[i], hierarchy[i + 1]));
      break;
    }
  }
}

function isBackGroundImage(img) {
  return (
    img.parent().hasClass('program-print-illustration') &&
    img.is(':first-child')
  );
}

function adaptBackground(src) {
  $('#app-print-program').css('background-image', 'url("' + src + '")');
}
