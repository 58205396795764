import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxCameraPosition from './mapbox-gl-camera-position';
import MapboxLinkMarkers from './mapbox-gl-link-markers';
import MapboxDisplayElevation from './mapbox-gl-display-elevation';
import MapboxLayer from './mapbox-gl-layer';

import { coordinatesAreEqual } from '../tools';
import { JSONParse, debounce } from 'tools/utilities.js';
import { popupMgr, deletePopup, clusterPopup } from './popup-manager';

// ==================================================================================================
// API
// ==================================================================================================

export default {
  create,
  remove,
  isCreated,
  load,
  isRendered,
  getCanvas,
  center,
  fitBounds,
  addGeocoder,
  editableMarkers,
  addMarker,
  removeMarker,
  getMarkerElevation,
  drawLine,
  removeLine,
  manageLayer,
  manageCameraPosition,
  manageLinkMarkers,
  manageDisplayElevation,
};

// ==================================================================================================
// Load & Display Map
// ==================================================================================================

function create(
  containerId,
  { interactive, printable, scroll, pictograms, clusters, token, publicUrl },
  { layer }
) {
  mapboxgl.accessToken = token;

  const map = new mapboxgl.Map({
    container: containerId,
    preserveDrawingBuffer: printable,
    maxZoom: 17,
    style: getStyleUrl(layer),
    interactive,
  });

  const language = document.documentElement.lang;
  map.addControl(new MapboxLanguage({ defaultLanguage: language }));
  if (interactive) {
    map.addControl(new mapboxgl.NavigationControl(), 'top-left');

    map.scrollZoom.disable();
    map.scrollZoom.setWheelZoomRate(0.02);
    const scrollHideMsg = debounce(() => scroll.hideMsg(), 750);

    map.on('wheel', (event) => {
      if (event.originalEvent.ctrlKey) {
        event.originalEvent.preventDefault();
        if (!map.scrollZoom._enabled) map.scrollZoom.enable();
      } else {
        scroll.displayMsg();
        scrollHideMsg();
        if (map.scrollZoom._enabled) map.scrollZoom.disable();
      }
    });
  }

  return {
    map,
    mapbox: {
      containerId,
      printable,
      pictograms,
      clusters,
      publicUrl,
      markers: {
        pins: [],
        icons: [],
      },
      routes: {},
    },
  };
}

function remove({ map }) {
  map.remove();
}

async function isCreated({ map }) {
  await map.once('load');
}

async function load({ map, mapbox }, markers, lines) {
  await loadMapContent({ map, mapbox }, markers, lines);
}

async function loadMapContent({ map, mapbox }, markers, lines) {
  await loadAllImages({ map, mapbox });
  loadRoutes({ map, mapbox }, lines);
  loadIcons({ map, mapbox }, markers);
  loadPins({ map, mapbox }, markers);
  loadTerrain(map);
  loadSky(map);
}

async function loadAllImages({ map, mapbox }) {
  const images = mapbox.pictograms.getList(mapbox.publicUrl);
  await Promise.all(images.map((i) => loadImage(map, i.code, i.url)));
}

function loadImage(map, name, imageUrl) {
  return new Promise(function (resolve, reject) {
    map.loadImage(imageUrl, function (error, image) {
      if (error) reject(error);
      map.addImage(name, image);
      resolve();
    });
  });
}

function loadRoutes({ map, mapbox }, lines) {
  const routes = [];
  lines.forEach((line) => {
    const route = buildLine(line.coordinates);
    routes.push(route);
    mapbox.routes[line.id] = route;
  });

  addSource({ map, mapbox }, 'routes', routes, { cluster: false });
  const color = isStyle(map, 'satellite') ? '#111111' : '#555555';
  map.addLayer(getDefaultRouteLayer('routes', color));
}

function loadPins({ map, mapbox }, markers) {
  // Source
  const pins = markers
    .filter((marker) => marker.type.isPin)
    .map((marker) => buildMarker(marker.center, marker.type));

  mapbox.markers.pins = pins;
  addSource({ map, mapbox }, 'pins', pins);

  // Shadow
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pin-shadows', null, {
      'icon-image': 'shadow',
      'icon-anchor': 'bottom',
      'icon-offset': [10, 0],
    })
  );

  // Color
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pin-c', ['has', 'cluster'], {
      'icon-image': buildLayerClusterIcon('pinColor', mapbox.clusters.priority),
      'icon-anchor': 'bottom',
    })
  );
  map.addLayer(
    // Layer id has to be 'pins' because it is used by the map events
    getDefaultMarkerLayer('pins', 'pins', ['!', ['has', 'cluster']], {
      'icon-image': ['get', 'pinColor'],
      'icon-anchor': 'bottom',
    })
  );

  // Icon
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pin-icon-c', ['has', 'cluster'], {
      'icon-image': buildLayerClusterIcon('icon', mapbox.clusters.priority),
      'icon-anchor': 'bottom',
      'icon-offset': [0, -17],
    })
  );
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pin-icon', ['!', ['has', 'cluster']], {
      'icon-image': ['get', 'icon'],
      'icon-anchor': 'bottom',
      'icon-offset': [0, -17],
    })
  );

  // Cluster Count
  if (!mapbox.printable) {
    const layout = {
      'text-field': '●',
      'text-size': 35,
      'text-anchor': 'bottom',
      'text-offset': [0.2, -0.5],
    };
    map.addLayer(
      getDefaultMarkerLayer('pins', 'pin-c-nb-bg', ['has', 'cluster'], layout, {
        'text-color': '#ffffff',
      })
    );

    map.addLayer(
      getDefaultMarkerLayer('pins', 'pin-c-nb', ['has', 'cluster'], {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 12,
        'text-anchor': 'bottom',
        'text-offset': [0.6, -2.6],
      })
    );
  }
}

function loadIcons({ map, mapbox }, markers) {
  const icons = markers
    .filter((marker) => !marker.type.isPin)
    .map((marker) => buildMarker(marker.center, marker.type));

  mapbox.markers.icons = icons;
  addSource({ map, mapbox }, 'icons', icons);

  map.addLayer(
    // Layer id has to be 'icons' because it is used by the map events
    getDefaultMarkerLayer('icons', 'icons', ['!', ['has', 'cluster']], {
      'icon-image': ['get', 'icon'],
    })
  );
}

function loadTerrain(map) {
  map.addSource('terrain', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.terrain-rgb',
    tileSize: 512,
    maxzoom: 14,
  });
  map.setTerrain({ source: 'terrain' });
}

function loadSky(map) {
  map.addLayer({
    id: 'sky',
    type: 'sky',
    paint: {
      // set up the sky layer to use a color gradient
      'sky-type': 'gradient',
      // the sky will be lightest in the center and get darker moving radially outward
      // this simulates the look of the sun just below the horizon
      'sky-gradient': [
        'interpolate',
        ['linear'],
        ['sky-radial-progress'],
        0.8,
        'rgba(135, 206, 235, 1.0)',
        1,
        'rgba(0,0,0,0.1)',
      ],
      'sky-gradient-center': [0, 0],
      'sky-gradient-radius': 90,
      'sky-opacity': [
        'interpolate',
        ['exponential', 0.1],
        ['zoom'],
        5,
        0,
        22,
        1,
      ],
    },
  });
}

// ==================================================================================================
// Canvas
// ==================================================================================================

async function isRendered({ map }) {
  await map.once('idle');
}

function getCanvas({ map }) {
  return map.getCanvas();
}

// ==================================================================================================
// Center Map
// ==================================================================================================

function center({ map }, animate, center, zoom, pitch = 0, bearing = 0) {
  const coord = coordMgr.toMapbox(center);
  map.flyTo({ animate, center: coord, zoom, pitch, bearing });
}

function fitBounds({ map }, animate, bounds) {
  const bbox = bounds.reduce(function (bbox, c) {
    return bbox.extend(coordMgr.toMapbox(c));
  }, new mapboxgl.LngLatBounds());

  map.fitBounds(bbox, { animate, padding: 70 });
}

// ==================================================================================================
// Geocoder
// ==================================================================================================

function addGeocoder({ map }, { resultFound }) {
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    enableEventLogging: false,
    marker: false,
    collapsed: true,
    clearAndBlurOnEsc: true,
    minLength: 3,
  });

  geocoder.on('result', function (response) {
    const result = { center: coordMgr.toBuilder(response.result.center) };
    if (response.result.bbox) {
      result.bbox = [
        coordMgr.toBuilder([response.result.bbox[0], response.result.bbox[1]]),
        coordMgr.toBuilder([response.result.bbox[2], response.result.bbox[3]]),
      ];
    }
    resultFound(result);
  });

  map.addControl(geocoder);
}

// ==================================================================================================
// Map Interactivity
// ==================================================================================================

function editableMarkers(
  { map, mapbox },
  { clickOnMap, clickOnMarker, rightClickOnMarker }
) {
  const markerSources = Object.keys(mapbox.markers);
  const popup = popupMgr();

  map.on('click', function (e) {
    if (!popup.isOpen()) {
      const markerAtClickPosition = map
        .queryRenderedFeatures(e.point)
        .filter((feature) => markerSources.indexOf(feature.source) !== -1);

      if (markerAtClickPosition.length === 0) {
        clickOnMap(e.lngLat);
      }
    }
  });

  markerSources.forEach((source) => {
    // Hover
    map.on('mouseenter', source, function () {
      if (!popup.isOpen()) {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    map.on('mouseleave', source, function () {
      map.getCanvas().style.cursor = '';
    });

    // Click
    map.on('click', source, function (e) {
      if (!popup.isOpen()) {
        const coordinates = getEventCoordinates(e);
        const marker = e.features[0].properties;

        const content = marker.cluster
          ? clusterPopup.content
          : deletePopup.content(mapbox.publicUrl);
        popup.build(map, coordinates, marker.isPin, content);

        if (!marker.cluster) {
          const center = JSONParse(marker.center, {});
          deletePopup.setClickEvent(
            mapbox.containerId,
            center,
            popup,
            clickOnMarker
          );
        }
      }
    });

    map.on('contextmenu', source, function (e) {
      if (!popup.isOpen()) {
        const coordinates = getEventCoordinates(e);
        const marker = e.features[0].properties;

        const content = marker.cluster
          ? clusterPopup.content
          : mapbox.pictograms.popup.content(mapbox.publicUrl);
        popup.build(map, coordinates, marker.isPin, content);

        if (!marker.cluster) {
          const center = JSONParse(marker.center, {});
          mapbox.pictograms.popup.setClickEvent(
            mapbox.containerId,
            center,
            rightClickOnMarker
          );
        }
      }
    });
  });
}

function getEventCoordinates(e) {
  const coordinates = e.features[0].geometry.coordinates.slice();

  // Ensure that if the map is zoomed out such that multiple copies of the
  // feature are visible, the popup appears over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  return coordinates;
}

// ==================================================================================================
// Update Markers & Lines
// ==================================================================================================

function addMarker({ map, mapbox }, center, type) {
  const source = type.isPin ? 'pins' : 'icons';
  const newMarkers = [...mapbox.markers[source]];
  newMarkers.push(buildMarker(center, type));

  updateSource({ map, mapbox }, source, newMarkers);
}

function removeMarker({ map, mapbox }, center) {
  Object.keys(mapbox.markers).forEach((source) => {
    const markers = mapbox.markers[source];
    const newMarkers = markers.filter((marker) => {
      return !coordinatesAreEqual(marker.properties.center, center);
    });

    if (newMarkers.length !== markers.length) {
      updateSource({ map, mapbox }, source, newMarkers);
    }
  });
}

function getMarkerElevation({ map }, center) {
  const elevation = map.queryTerrainElevation(center);
  return Math.max(elevation, 0);
}

function updateSource({ map, mapbox }, source, newMarkers) {
  mapbox.markers[source] = newMarkers;
  map.getSource(source).setData({
    type: 'FeatureCollection',
    features: newMarkers,
  });
}

function drawLine({ map, mapbox }, lineId, coordinates) {
  mapbox.routes[lineId] = buildLine(coordinates);
  map.getSource('routes').setData({
    type: 'FeatureCollection',
    features: Object.keys(mapbox.routes).map((id) => mapbox.routes[id]),
  });
}

function removeLine({ map, mapbox }, lineId) {
  delete mapbox.routes[lineId];
  map.getSource('routes').setData({
    type: 'FeatureCollection',
    features: Object.keys(mapbox.routes).map((id) => mapbox.routes[id]),
  });
}

function manageLinkMarkers({ map }, isLinked, linkMarkers) {
  const container = $('.mapboxgl-control-container');
  const linkMarkersControl = new MapboxLinkMarkers(isLinked);
  map.addControl(linkMarkersControl, 'top-left');

  container.on('click', linkMarkersControl.toggleSelector(), function () {
    const newIsLinked = linkMarkersControl.toggleIsLinked();
    linkMarkers(newIsLinked);
  });
}

// ==================================================================================================
// Camera Position
// ==================================================================================================

function manageCameraPosition({ map }, cameraPosition, setCameraPosition) {
  const container = $('.mapboxgl-control-container');
  const cameraControl = new MapboxCameraPosition(cameraPosition);
  map.addControl(cameraControl, 'top-left');

  container.on('mouseenter', cameraControl.saveSelector(), function () {
    $('.tp-table-map-print-view').removeClass('hidden');
  });
  container.on('mouseleave', cameraControl.saveSelector(), function () {
    $('.tp-table-map-print-view').addClass('hidden');
  });
  container.on('click', cameraControl.saveSelector(), function () {
    setCameraPosition({
      center: map.getCenter(),
      zoom: map.getZoom(),
      pitch: map.getPitch(),
      bearing: map.getBearing(),
    });
    cameraControl.displayDelete();
  });
  container.on('click', cameraControl.deleteSelector(), function () {
    setCameraPosition(null);
    cameraControl.hideDelete();
  });
}

// ==================================================================================================
// Map Properties
// ==================================================================================================

function manageLayer({ map }, layer, setLayer) {
  const container = $('.mapboxgl-control-container');
  const layerControl = new MapboxLayer(layer);
  map.addControl(layerControl, 'top-left');

  container.on('click', layerControl.toggleSelector(), function () {
    $(layerControl.listSelector()).toggleClass('hidden');
  });

  container.on('click', layerControl.layerSelector(), function () {
    const newLayer = layerControl.chooseLayer($(this).data('layer'));
    if (newLayer) {
      map.setStyle(getStyleUrl(newLayer));
      setLayer(newLayer);
    }
    $(layerControl.listSelector()).toggleClass('hidden');
  });
}

function manageDisplayElevation({ map }, isDisplayed, displayElevation) {
  const container = $('.mapboxgl-control-container');
  const elevationControl = new MapboxDisplayElevation(isDisplayed);
  map.addControl(elevationControl, 'top-left');

  container.on('click', elevationControl.toggleSelector(), function () {
    const newIsDisplayed = elevationControl.toggleIsDisplayed();
    displayElevation(newIsDisplayed);
  });
}

// ==================================================================================================
// Clusters
// ==================================================================================================

function buildLayerClusterIcon(property, clustersPriority) {
  if (clustersPriority.length === 0) return `{${property}}`;
  if (clustersPriority.length === 1) return clustersPriority[0][property];

  const clusterProperty = [];
  clusterProperty.push('case');

  clustersPriority.forEach((val, index, arr) => {
    if (index < arr.length - 1) {
      clusterProperty.push(['>', ['get', 'is_' + val.type], 0]);
    }
    clusterProperty.push(val[property]);
  });
  return clusterProperty;
}

function buildSourceClusterProperties(clustersPriority) {
  const clusterProperties = {};

  clustersPriority.forEach((val, index, arr) => {
    if (index < arr.length - 1) {
      clusterProperties['is_' + val.type] = [
        '+',
        ['case', ['==', ['get', 'type'], val.type], 1, 0],
      ];
    }
  });

  return clusterProperties;
}

// ==================================================================================================
// Tools
// ==================================================================================================

const coordMgr = {
  toMapbox: (c) => [c.lng, c.lat],
  toBuilder: (c) => ({ lng: c[0], lat: c[1] }),
};

function getStyleUrl(layer) {
  const base = 'mapbox://styles/mapbox/';
  switch (layer) {
    case 'streets':
      return `${base}streets-v11`;
    case 'outdoors':
      return `${base}outdoors-v11`;
    case 'satellite':
      return `${base}satellite-streets-v11`;
    default:
      throw `Unknown layer: ${layer}`;
  }
}

function isStyle(map, layer) {
  return map.getStyle().sprite.includes(layer);
}

function addSource({ map, mapbox }, source, features, { cluster = true } = {}) {
  const properties = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
  };

  if (cluster) {
    map.addSource(source, {
      ...properties,
      cluster: true,
      clusterMaxZoom: 1000,
      clusterRadius: 15,
      clusterProperties: buildSourceClusterProperties(mapbox.clusters.priority),
    });
  } else {
    map.addSource(source, properties);
  }
}

function getDefaultRouteLayer(source, color) {
  return {
    id: source,
    type: 'line',
    source,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': 2,
    },
  };
}

function getDefaultMarkerLayer(source, id, filter, layout = {}, paint = {}) {
  const layer = {
    id,
    type: 'symbol',
    source,
    layout: {
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-ignore-placement': true,
      ...layout,
    },
    paint,
  };
  if (filter) layer.filter = filter;
  return layer;
}

function buildMarker(center, type) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coordMgr.toMapbox(center),
    },
    properties: { ...type, center },
  };
}

function buildLine(coordinates) {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: coordinates.map((c) => coordMgr.toMapbox(c)),
    },
  };
}
