import dialog from 'tools/dialog';
import datatables from 'tools/datatables';

import './dolibarr.scss';

export function dolibarr() {
  $(function () {
    printInNewTab();
    administration();
    synchronization();
  });
}

function printInNewTab() {
  if (window.self !== window.top) {
    $('.program-header #program-header-print').click(function () {
      window.open($(this).attr('href'));
      return false;
    });
  }
}

function administration() {
  $('#DolibarrSubmitButton').click(function () {
    dialog.loading($(this).data('running-title'));
  });
}

function synchronization() {
  let options = {
    order: [[4, 'desc']],
  };
  datatables.display('list', '.app-dolibarr-queue', options);

  $('#DolibarrRunSynchronization').click(function () {
    if ($(this).hasClass('disabled')) {
      return;
    }

    $(this).addClass('disabled');
    $(this).text($(this).data('label-running'));
    $('.app-dolibarr-queue-running').removeClass('hidden');

    const url = window.location.href.replace('/Administration', '/Synchronize');
    $.get(url, (result) => {
      $('.app-dolibarr-queue-running').addClass('hidden');
      $(this).text($(this).data('label-success'));
      $(this).removeClass('disabled');
      $('.app-dolibarr-queue-reload').text(
        $('.app-dolibarr-queue-reload')
          .text()
          .replace('%nbSuccess%', result.nbSuccess)
          .replace('%nbFailure%', result.nbFailure)
      );
      $('.app-dolibarr-queue-reload').removeClass('hidden');
    });
  });

  $('.app-dolibarr-queue-reload').click(function () {
    window.location.assign(window.location.href);
  });
}
