import 'jquery-ui/draggable';
import 'jquery-ui/sortable';
import 'jquery-ui-css/all.css';
import 'jquery-ui-touch-punch';

import router from 'tools/router';
import dialog from 'tools/dialog';
import { initializeFormFields } from 'table/form';

import './css/program-header.scss';
import './css/warning.scss';

// Set as a constant instead of just a function because of weird bug when building with webpack in mode Production.
// There is a bug in the console saying the function is not defined...
const programElementClick = function () {
  followProgramButton($(this));
};

export function programHeader() {
  $(function () {
    initializeProgramHeader();
    initializeTemplateCopy();
  });
}

function initializeProgramHeader() {
  $(
    '#program-header-program, #program-header-days li, #program-header-cotation-add, #Program-cotations li'
  ).on('click', programElementClick);

  $('#program-header-draggable').on('click', function () {
    $(this).data('day-number', $('#program-header-days li').length + 1);
    followProgramButton($(this));
  });

  $('#program-header-draggable').draggable({
    connectToSortable: '#program-header-days',
    helper: 'clone',
    revert: 'invalid',
  });

  $('#program-header-days').sortable({
    revert: true,
    start: function (event, ui) {
      ui.item.off('click', programElementClick);
      $('#program-header-days').sortable(
        'option',
        'cancel',
        '#program-header-days'
      );
    },
    stop: function (event, ui) {
      ui.item.on('click', programElementClick);
      $('#program-header-days').sortable('option', 'cancel', null);
    },
    update: function (event, ui) {
      $('#program-header-days li').each(function (index) {
        $(this).text(index + 1);
      });

      ui.item.data('day-number', ui.item.text());

      if (ui.item.data('action') == 'add') {
        followProgramButton(ui.item);
      } else {
        ui.item.html('&nbsp;&nbsp;').addClass('small-loading');

        let routingParams = {
          day: ui.item.data('record-id'),
          dayNumber: ui.item.data('day-number'),
        };
        $.post(
          //URL
          router.generate('tp_planner_day_editDayNumber_AJAX', routingParams),
          // Success
          function () {
            followProgramButton(ui.item);
          }
        );
      }
    },
  });
}

function followProgramButton(button) {
  if (button.data('action') == 'add') {
    let urlParameters;

    if (button.data('table').startsWith('Day')) {
      let dayNumber = button.data('day-number');

      urlParameters = [
        '__form_program=' + button.data('program'),
        '__form_dayNumber=' + dayNumber,
      ];
    } else {
      urlParameters = ['__form_program=' + button.data('program')];
    }

    window.location.href =
      router.generate('tp_table_add', {
        table: button.data('table'),
      }) +
      '?' +
      urlParameters.join('&');
  } else {
    window.location.href = router.generate(
      'tp_table_' + button.data('action'),
      {
        table: button.data('table'),
        id: button.data('record-id'),
      }
    );
  }
}

function initializeTemplateCopy() {
  let template = $('.program-header-program-template');
  if (template.length == 0) return null;

  displayTemplateCopyForm(template);
}

function displayTemplateCopyForm(template) {
  let templateId = template.data('template');
  let dialogTitle = template.text();

  template.click(function () {
    dialog.loading(dialogTitle);

    $.post(
      //URL
      router.generate('tp_planner_template_copyForm_AJAX'),

      // Success
      function (form) {
        dialog.open(dialogTitle, form, 600);
        $(dialog.DIALOG_ID + ' .col-sm-2')
          .removeClass('col-sm-2')
          .addClass('col-sm-4');
        $(dialog.DIALOG_ID + ' .col-sm-10')
          .removeClass('col-sm-10')
          .addClass('col-sm-8');

        initializeFormFields(dialog.DIALOG_ID);

        let dialogHeight = $(dialog.DIALOG_ID).css('height');
        $('#copy_template_departureDate')
          .parent()
          .on('dp.show', function () {
            $(dialog.DIALOG_ID).css('height', '350px');
          });
        $('#copy_template_departureDate')
          .parent()
          .on('dp.hide', function () {
            $(dialog.DIALOG_ID).css('height', dialogHeight);
          });

        $('#copy_template_departureDate')
          .parent()
          .on('dp.change', function () {
            if ($('#copy_template_departureDate').val()) {
              $('#CopyTemplateConfirmation').removeClass('disabled');
            } else {
              $('#CopyTemplateConfirmation').addClass('disabled');
            }
          });
        $('#CopyTemplateConfirmation').click(function () {
          if (!$(this).hasClass('diabled')) {
            copyTemplate(templateId, dialogTitle);
          }
        });
        $('#CopyTemplateCancel').click(function () {
          dialog.close();
        });
      }
    );
  });
}

function copyTemplate(templateId, dialogTitle) {
  let departureDate = $('#copy_template_departureDate')
    .parent()
    .data('DateTimePicker')
    .date();
  let departureDateAsArray = {
    day: departureDate.format('D'),
    month: departureDate.format('M'),
    year: departureDate.format('YYYY'),
  };
  let mainContactId = $('#copy_template_mainContact').val();

  dialog.loading(dialogTitle);

  let postParams = {
    departureDateAsArray: departureDateAsArray,
    mainContactId: mainContactId,
  };

  $.post(
    //URL
    router.generate('tp_planner_template_copy_AJAX', {
      template: templateId,
    }),
    // Data
    postParams,
    // Success
    function (form) {
      dialog.open(dialogTitle, form, 600, true);
    }
  );
}
