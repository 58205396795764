import dialog from 'tools/dialog';
import router from 'tools/router';

import './map-helper.scss';

export function activateMapHelper(container = '') {
  $(container + ' .tp-table-map .tp-table-helper').click(function () {
    const dialogLabel = $(this).attr('title');
    dialog.loading(dialogLabel);

    $.post(
      //URL
      router.generate('app_table_helper_map_AJAX'),

      // Success
      function (helper) {
        dialog.open(dialogLabel, helper, 1200, true);
      }
    );
  });
}
