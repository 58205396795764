import dialog from 'tools/dialog';

import { maintenance } from './maintenance/maintenance.js';
import { myConsole } from './console/console.js';

export function administration() {
  maintenance();
  myConsole();

  $(function () {
    // Dev options
    if (!_PRODUCTION) {
      // Handle AJAX errors
      $(document).ajaxError(function (event, jqxhr, settings) {
        if (jqxhr.responseText !== undefined) {
          dialog.open(
            'AJAX ERROR - URL : ' + settings.url,
            jqxhr.responseText,
            '1300',
            true
          );
        }
      });

      // Dark Mode
      let darkmodeCookie = document.cookie.match(
        '(^|;) ?fitmytrip_darkmode=([^;]*)(;|$)'
      );
      let darkmode = darkmodeCookie ? darkmodeCookie[2] == 'true' : false;
      let containers = 'body, .container';

      if (darkmode) $(containers).addClass('dark-mode');
      $('#navbar').append(
        $('<i>').addClass('glyphicon glyphicon-lamp dark-mode-button')
      );

      $('.dark-mode-button').click(function () {
        darkmode = !darkmode;
        $(containers).toggleClass('dark-mode');
        document.cookie =
          'fitmytrip_darkmode=' + darkmode + ';domain=.localhost';
      });
    }
  });
}
