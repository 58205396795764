const titleDependencies = {
  H1: ['H2', 'H3', 'H4', 'H5', 'DIV', 'P', 'UL', 'TABLE'],
  H2: ['H3', 'H4', 'H5', 'DIV', 'P', 'UL', 'TABLE'],
  H3: ['H4', 'H5', 'DIV', 'P', 'UL', 'TABLE'],
  H4: ['H5', 'DIV', 'P', 'UL', 'TABLE'],
  H5: ['DIV', 'P', 'UL', 'TABLE'],
};

export function newNode(DOM) {
  const tagName = getTagName(DOM);

  return {
    DOM: DOM,
    bottomOffset: () => getBottomOffset(DOM),
    isFirstChild: () => isFirstChild(DOM),
    isPageBreakSelectable: () => isPageBreakSelectable(DOM),
    isPageBreakSelected: () => isPageBreakSelected(DOM),
    is: (selector) => is(DOM, selector),

    getChildren: () => getChildren(DOM),
    isContentSplittable: () => isContentSplittable(DOM),
    setContent: function (newContent) {
      return setContent(this, newContent);
    },

    isTitle: () => isTitle(tagName),
    dependsOn: (titleNode) => dependsOn(tagName, titleNode),

    next: () => getNextNode(DOM),
    copy: (deep = true) => copy(DOM, deep),
    delete: () => deleteNode(DOM),

    beforeNode: function (otherNode) {
      return beforeNode(this, otherNode);
    },
    afterNode: function (otherNode) {
      return afterNode(this, otherNode);
    },
    appendToNode: function (otherNode) {
      return appendToNode(this, otherNode);
    },
  };
}

function getBottomOffset(DOM) {
  // Get node bottom offset not taking into account padding or margin
  const offset = DOM.offset();
  const outerHeight = DOM.outerHeight();

  const lastDOM = isContentSplittable(DOM) ? getChildren(DOM) : DOM;
  const bottomPadding = Number(lastDOM.css('padding-bottom').slice(0, -2));

  return offset.top + outerHeight - bottomPadding;
}

function isFirstChild(DOM) {
  return DOM.is(':first-child');
}

function isPageBreakSelectable(DOM) {
  return DOM.hasClass('program-print-config-break-selectable');
}

function isPageBreakSelected(DOM) {
  return DOM.hasClass('program-print-config-break-selected');
}

function is(DOM, selector) {
  return DOM.is(selector);
}

function getChildren(DOM) {
  return getContent(DOM).children();
}

function isContentSplittable(DOM) {
  const selectors = [
    '.program-print-description',
    '.program-print-country-details',
    '.program-print-quote',
  ];

  return getContent(DOM).is(selectors.join(','));
}

function setContent(node, newContent) {
  getContent(node.DOM).html(newContent);
  return node;
}

function isTitle(tagName) {
  return titleDependencies[tagName] !== undefined;
}

function dependsOn(tagName, titleNode) {
  const dependencies = titleDependencies[getTagName(titleNode.DOM)];
  return dependencies.indexOf(tagName) != -1;
}

function getNextNode(DOM) {
  const nextNode = DOM.next();

  if (nextNode.length == 1) {
    return newNode(nextNode);
  } else {
    return null;
  }
}

function copy(DOM, deep = true) {
  const copy = deep ? DOM.clone() : $(DOM.get(0).cloneNode());

  const copyId = copy.attr('id');
  if (copyId) copy.attr('id', copyId + '-2');

  return newNode(copy);
}

function deleteNode(DOM) {
  DOM.remove();
  return true;
}

function beforeNode(node, otherNode) {
  otherNode.DOM.before(node.DOM);
  return node;
}

function afterNode(node, otherNode) {
  otherNode.DOM.after(node.DOM);
  return node;
}

function appendToNode(node, otherNode) {
  otherNode.DOM.append(node.DOM);
  return node;
}

// Tools
function getContent(DOM) {
  if (isPageBreakSelectable(DOM) || isPageBreakSelected(DOM)) {
    return DOM.children(':eq(1)');
  } else {
    return DOM;
  }
}

function getTagName(DOM) {
  return getContent(DOM).prop('tagName');
}
