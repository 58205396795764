import router from 'tools/router';
import dialog from 'tools/dialog';

import './images.scss';

// Filters is a list of functions stored here to be executed at the right moment
// key : grid Id | value : function
let filters = {};

export function defineImageGridEvents() {
  $(function () {
    displayGridInPopup();
    setImagesSelection();
    manageImageCreationForm();
  });
}

export function displayImageGridInForm(container = '', filter = null) {
  $(
    container +
      ' .tp-table-images-grid-container,' +
      container +
      '.tp-table-images-grid-container'
  ).each(function () {
    // Initialize
    let grid = $(this);
    registerFilter(grid.attr('id'), filter);

    // Build POST Data
    let gridParams = {
      CRUD: grid.data('crud'),
      imagesId: $(this).find('select').val(),
      filter: getFilter(grid.attr('id')),
      edit: true,
      add: grid.data('add'),
    };

    // Run the POST Request
    $.post(
      //URL
      router.generate('tp_table_images_grid_AJAX'),
      // Data
      { gridParams: gridParams },
      // Success
      function (data) {
        $(grid).find('.tp-table-images-grid').remove();
        $(grid).append(data);

        // Dialog in loading state if the function is triggered by closing the selection popup
        dialog.close();
      }
    );
  });
}

function displayGridInPopup() {
  const selector = '.table-form-row, .app-zoom-form';
  $(selector).on('click', '.tp-table-images-more span', function () {
    // Initialize
    let grid = $(this).closest('.tp-table-images-grid-container');

    let edit = $(this).data('edit');
    let interact = $(this).data('interact');
    let title = getPopupTitle(grid, $(this), edit);

    dialog.loading(title);

    // Build POST Data
    const imagesId = edit
      ? grid.find('select').val()
      : $(this).data('images-id');
    const CRUD = edit ? grid.data('crud') : $(this).data('crud');
    let gridParams = {
      source: grid.attr('id'),
      CRUD,
      imagesId,
      limitNb: 0,
      edit: edit,
      interact: interact,
      add: grid.data('add'),
    };
    const filter = getFilter(grid.attr('id'));
    if (filter) {
      gridParams['filter'] = filter;
    }

    // Run the POST Request
    $.post(
      //URL
      router.generate('tp_table_images_grid_AJAX'),
      // Data
      { gridParams: gridParams },
      // Success
      function (data) {
        data = $('<div>').addClass('container').html(data);

        let closePopup = interact
          ? () => closeInteractivePopup(grid, title)
          : undefined;
        dialog.open(
          title,
          data,
          $('.container:eq(0)').width() + 100,
          true,
          closePopup
        );
      }
    );
  });
}

function getPopupTitle(grid, more, edit) {
  if (edit) {
    return grid.data('label');
  } else {
    return more.closest('[id^="Line_"]').find('.app-zoom-label').text();
  }
}

function closeInteractivePopup(grid, title) {
  let selectedImages = $('.tp-table-images-interact.tp-table-images-selected')
    .map(function () {
      return $(this).children('img').data('id');
    })
    .get();
  dialog.loading(title);

  grid.find('select').val(selectedImages);
  displayImageGridInForm('#' + grid.attr('id'));
}

function setImagesSelection() {
  $(dialog.DIALOG_ID).on(
    'click',
    '.tp-table-images-display-available',
    function () {
      $('.tp-table-images-available-container').toggleClass('hidden');
      dialog.center();
    }
  );

  $(dialog.DIALOG_ID).on('click', '.tp-table-images-interact', function () {
    $(this).toggleClass('tp-table-images-selected');
  });
}

function manageImageCreationForm() {
  // Display Button
  $(dialog.DIALOG_ID).on('click', '.tp-table-images-add', function () {
    $('.tp-table-images-add-form').html($('<div>').addClass('loading'));
    dialog.center();

    let source = $(this).data('source');
    let CRUD = $('#' + source).data('crud');
    let filter = getFilter(source);
    let routeParams = { table: CRUD, light: 'light' };
    Object.keys(filter).map(function (key) {
      routeParams['__form_' + key] = Array.isArray(filter[key])
        ? filter[key][0]
        : filter[key];
    });

    $.post(
      //URL
      router.generate('tp_table_add', routeParams),
      // Success
      function (data) {
        manageRequestReturn(CRUD, data);
      }
    );
  });

  // Cancel Button
  $(dialog.DIALOG_ID).on(
    'click',
    '.tp-table-images-add-form a',
    function (event) {
      event.preventDefault();

      $('.tp-table-images-add-form').empty();
      dialog.center();
    }
  );

  // Submit Button
  $(dialog.DIALOG_ID).on(
    'submit',
    '.tp-table-images-add-form form',
    function (event) {
      event.preventDefault();

      $('.tp-table-images-add-form').html($('<div>').addClass('loading'));
      dialog.center();

      let source = $('.tp-table-images-add').data('source');
      let CRUD = $('#' + source).data('crud');
      let formData = new FormData(this);

      $.ajax({
        url: router.generate('tp_table_add', {
          table: CRUD,
          light: 'light',
          returnId: 'returnId',
        }),
        type: 'POST',
        data: formData,
        contentType: false,
        processData: false,
        success: function (data) {
          manageRequestReturn(CRUD, data);
        },
      });
    }
  );
}

function manageRequestReturn(CRUD, data) {
  let imageId = data.id;

  if (typeof imageId === 'undefined') {
    displayImageCreationForm(data);
  } else {
    addImageAndRemoveCreationForm(CRUD, imageId);
  }
}

function displayImageCreationForm(data) {
  let title = $('.tp-table-images-add').text();

  $('.tp-table-images-add-form').html(data);
  $('.tp-table-images-add-form #image_identification').text(title);
  $('.tp-table-images-add-form')
    .find('.table-form-row')
    .removeClass('invisible');
  dialog.center();
}

function addImageAndRemoveCreationForm(CRUD, imageId) {
  $.post(
    //URL
    router.generate('tp_table_images_image_AJAX'),
    // Data
    { CRUD: CRUD, id: imageId },
    // Success
    function (data) {
      $('.ui-dialog-content .tp-table-images-selected-container').append(data);

      let source = $('.tp-table-images-add').data('source');
      $('#' + source + ' select').append($('<option>').val(imageId));

      $('.tp-table-images-add-form').empty();
      dialog.center();
    }
  );
}

function registerFilter(id, filter = null) {
  if (filter) {
    filters[id] = filter;
  }
}

function getFilter(id) {
  if (filters[id]) {
    return filters[id]();
  } else {
    return null;
  }
}
