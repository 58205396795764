import router from 'tools/router';
import buildMap from 'tools/map';
import { activityElevationChart, programElevationChart } from 'tools/charts';

export function loadElevations() {
  const chartsToLoad =
    ':not(.program-print-config-hide-selected) > .program-print-elevation > canvas';

  $(chartsToLoad).each(function () {
    const { markersLists, displayOptions } = $(this).data('location');
    if ($(this).data('is-summary')) {
      programElevationChart($(this).attr('id'), markersLists);
    } else {
      if (displayOptions?.displayElevation) {
        activityElevationChart($(this).attr('id'), markersLists);
      }
    }
  });
}

export function loadMaps(isReady) {
  const mapsToLoad = $(
    ':not(.program-print-config-hide-selected) > .program-print-map > div'
  );
  let nbMapsToLoad = mapsToLoad.length;
  if (nbMapsToLoad > 0) {
    isReady.addToLoadingList('maps');
  }

  mapsToLoad.each(function () {
    const options = {
      interactive: false,
      printable: true,
      ...$(this).data('options'),
    };
    const mapStatus = $(this).data('status');

    buildMap($(this).attr('id'), options, mapStatus).then((map) => {
      nbMapsToLoad--;
      if (nbMapsToLoad === 0) {
        isReady.loaded('maps');
      }

      const persist = $(this).data('persist');
      if (persist) {
        persistMap(persist.table, persist.id, map, $(this).parent());
      }
    });
  });
}

function persistMap(table, id, map, container) {
  const canvas = map.getCanvas();
  if (!canvas) return;

  canvas.toBlob((blob) => {
    const formData = new FormData();
    formData.append('persist_map[mapFile][file]', blob);

    $.ajax({
      type: 'POST',
      url: router.generate(`tp_planner_persist_map_${table}_AJAX`, {
        record: id,
      }),
      data: formData,
      processData: false,
      contentType: false,

      success: function (data) {
        if (data.success) {
          container.html(
            $('<img>').attr('src', data.url).attr('alt', data.alt)
          );
          map.remove();
        }
      },
    });
  });
}
