import router from 'tools/router';
import dialog from 'tools/dialog';

export function cityFields() {
  $(function () {
    initialize();
  });
}

function initialize() {
  $('.table-form-row,' + dialog.DIALOG_ID).on(
    'change',
    '[id$="_countries"],[id$="_country"]',
    function () {
      let formSelector = $(this).attr('id').split('_');
      formSelector.pop();
      formSelector = '#' + formSelector.join('_');

      const citiesKey = formSelector + '_cities,' + formSelector + '_city';

      if ($(citiesKey).length && $(this).val().length) {
        $(citiesKey)
          .prev('.input-group-addon')
          .children()
          .html($('<span>').addClass('small-loading'));

        const countriesOrCountryId = $(this).val();
        const url = Array.isArray(countriesOrCountryId)
          ? 'app_service_getCitiesPerCountries_AJAX'
          : 'app_service_getCitiesPerCountry_AJAX';
        const body = Array.isArray(countriesOrCountryId)
          ? { countriesId: countriesOrCountryId }
          : { countryId: countriesOrCountryId };

        $.post(
          //URL
          router.generate(url),
          // Data
          body,
          // Success
          function (countryCities) {
            const cities = $(citiesKey).val();
            $(citiesKey).children('option').remove();

            $.each(countryCities, function (index, city) {
              $(citiesKey).append(
                $('<option>').attr('value', city.cityId).text(city.cityName)
              );
            });

            $(citiesKey).val(cities);

            $(citiesKey).prev('.input-group-addon').children().empty();
          }
        );
      } else {
        $(citiesKey).children('option').remove();
      }
    }
  );
}
