import Chart from 'chart.js/auto';
import turf_length from '@turf/length';
import { lineString as turf_lineString } from '@turf/helpers';

// Activity Chart
export function activityElevationChart(containerId, markersLists) {
  resetLoadedChart(containerId);

  const distances = getStepsDistance(markersLists);
  const maxDistance = distances[distances.length - 1];

  const elevations = getStepsElevation(markersLists);

  if (!shouldDisplayChart(elevations)) {
    displayAlternative(containerId, elevations);
    return;
  }

  const config = {
    type: 'line',
    data: {
      labels: distances,
      datasets: [
        {
          label: 'Altitude',
          data: elevations,
          borderColor: '#873600',
          fill: { target: 'origin', above: '#db9e75' },
          tension: 0.2,
        },
      ],
    },
    options: {
      scales: {
        x: {
          type: 'linear',
          max: maxDistance,
          ticks: {
            callback: function (value) {
              return `${value} km`;
            },
          },
        },
        y: {
          ticks: {
            callback: function (value) {
              return `${value} m`;
            },
          },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          usePointStyle: true,
          callbacks: {
            label: function (context) {
              return ` ${context.dataset.label}: ${context.parsed.y} m`;
            },
            title: function (context) {
              return ` Distance: ${context[0].parsed.x} km`;
            },
          },
        },
      },
    },
  };

  loadChart(containerId, config);
}

function getStepsDistance(markersLists) {
  const stepsDistance = [0];
  const markers = getMarkers(markersLists);

  if (markers.length < 2) {
    return stepsDistance;
  }

  for (let i = 1; i < markers.length; i++) {
    const start = markers[i - 1];
    const end = markers[i];

    const line = turf_lineString([
      [start.center.lng, start.center.lat],
      [end.center.lng, end.center.lat],
    ]);
    const stepDistance = Math.round(turf_length(line) * 10) / 10;

    const fullDistance = stepsDistance.slice(-1)[0];
    stepsDistance.push(fullDistance + stepDistance);
  }

  return stepsDistance;
}

function getStepsElevation(markersLists) {
  const markers = getMarkers(markersLists);

  if (markers.length === 0) {
    return [];
  }

  return markers.map((marker) => marker.elevation ?? 0);
}

// Program Chart
export function programElevationChart(containerId, markersLists) {
  resetLoadedChart(containerId);

  const steps = getProgramSteps(markersLists);
  if (steps.length === 0) {
    hideChart(containerId);
    return;
  }

  const getMaxElevation = (max, step) => Math.max(max, step.elevation);
  const programMaxElevation = steps.reduce(getMaxElevation, 0);
  if (programMaxElevation === 0) {
    hideChart(containerId);
    return;
  }

  const maxDay = Math.ceil(steps[steps.length - 1].day);

  const config = {
    type: 'line',
    data: {
      datasets: [
        {
          label: 'Altitude',
          data: steps,
          borderColor: '#873600',
          fill: { target: 'origin', above: '#db9e75' },
          tension: 0.2,
        },
      ],
    },
    options: {
      parsing: {
        xAxisKey: 'day',
        yAxisKey: 'elevation',
      },
      scales: {
        x: {
          type: 'linear',
          max: maxDay,
          ticks: {
            stepSize: 1,
            callback: function (value) {
              return maxDay <= 10 ? `Jour ${value}` : `J-${value}`;
            },
          },
        },
        y: {
          ticks: {
            callback: function (value) {
              return `${value} m`;
            },
          },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          usePointStyle: true,
          callbacks: {
            label: function (context) {
              return ` ${context.dataset.label}: ${context.parsed.y} m`;
            },
            title: function (context) {
              return context[0].raw.name;
            },
          },
        },
      },
    },
  };

  loadChart(containerId, config);
}

function getProgramSteps(markersLists) {
  if (markersLists.length === 0) {
    return [];
  }

  const getMaxElevation = (max, marker) => Math.max(max, marker.elevation ?? 0);
  const activities = markersLists.map((activity) => {
    return {
      day: activity.day,
      name: activity.name,
      elevation: activity.markers.reduce(getMaxElevation, 0),
    };
  });

  // Split the X axis in as many days then spread the activities in the X axis area of their day
  const lastDay = activities[activities.length - 1].day;
  for (let currentDay = 1; currentDay <= lastDay; currentDay++) {
    const dayActivities = activities.filter(
      (activity) => activity.day === currentDay
    );

    dayActivities.forEach((activity, index) => {
      activity.day = currentDay - 1 + (index + 1) / (dayActivities.length + 1);
    });
  }

  return activities;
}

// Tools
const loadedCharts = {};

function resetLoadedChart(containerId) {
  if (loadedCharts[containerId]) {
    loadedCharts[containerId].destroy();
    delete loadedCharts[containerId];
  }

  const chart = displayChart(containerId);
  chart.nextElementSibling?.remove();
}

function loadChart(containerId, config) {
  const chart = new Chart(getChart(containerId), config);
  loadedCharts[containerId] = chart;
  return chart;
}

function shouldDisplayChart(elevations) {
  return elevations.length > 1;
}

function displayAlternative(containerId, elevations) {
  const alternative = document.createElement('div');

  if (elevations.length === 0) {
    alternative.innerHTML = 'Aucune donnée disponible.';
  } else {
    alternative.innerHTML = `Altitude : ${elevations[0]}m`;
  }

  const chart = hideChart(containerId);
  chart.after(alternative);
}

function getChart(containerId) {
  return document.getElementById(containerId);
}

function displayChart(containerId) {
  const chart = getChart(containerId);
  chart.classList.remove('hidden');
  return chart;
}

function hideChart(containerId) {
  const chart = getChart(containerId);
  chart.classList.add('hidden');
  return chart;
}

function getMarkers(markersLists) {
  return markersLists[0].markers;
}
