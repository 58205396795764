export default class MapboxLayer {
  constructor(layer) {
    this.layer = layer;
  }

  onAdd(map) {
    const icon = $('<span>')
      .addClass('mapboxgl-ctrl-icon')
      .attr('aria-hidden', 'true');
    const toggleButton = $('<button>')
      .addClass('app-map-layer-list-toggle')
      .attr('type', 'button')
      .attr('title', 'Toggle')
      .attr('aria-label', 'Toggle')
      .html(icon);

    const streetsLayer = $('<button>')
      .addClass('app-map-layer-select')
      .attr('type', 'button')
      .data('layer', 'streets')
      .html($('<span>').addClass('mapboxgl-ctrl-icon app-map-layer-streets'))
      .append($('<span>').text('Rues'));
    const outdoorsLayer = $('<button>')
      .addClass('app-map-layer-select')
      .attr('type', 'button')
      .data('layer', 'outdoors')
      .html($('<span>').addClass('mapboxgl-ctrl-icon app-map-layer-outdoors'))
      .append($('<span>').text('Relief'));
    const satelliteLayer = $('<button>')
      .addClass('app-map-layer-select')
      .attr('type', 'button')
      .data('layer', 'satellite')
      .html($('<span>').addClass('mapboxgl-ctrl-icon app-map-layer-satellite'))
      .append($('<span>').text('Satellite'));
    const layersList = $('<div>')
      .addClass('app-map-layer-list')
      .addClass('hidden')
      .html(streetsLayer)
      .append(outdoorsLayer)
      .append(satelliteLayer);

    const container = $('<div>')
      .addClass('mapboxgl-ctrl mapboxgl-ctrl-group app-map-layer')
      .html(toggleButton)
      .append(layersList);

    this._map = map;
    this._container = container.get(0);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  chooseLayer(layer) {
    if (this.layer === layer) {
      return null;
    }

    this.layer = layer;
    return this.layer;
  }

  toggleSelector() {
    return '.app-map-layer-list-toggle';
  }

  listSelector() {
    return '.app-map-layer-list';
  }

  layerSelector() {
    return '.app-map-layer-select';
  }
}
