export default class MapboxDisplayElevation {
  constructor(isDisplayed) {
    this.isDisplayed = isDisplayed;
  }

  onAdd(map) {
    const icon = $('<span>')
      .addClass('mapboxgl-ctrl-icon')
      .attr('aria-hidden', 'true');
    const toggleButton = $('<button>')
      .addClass('app-map-display-elevation-toggle')
      .attr('type', 'button')
      .attr('title', 'Toggle')
      .attr('aria-label', 'Toggle')
      .html(icon);
    const container = $('<div>')
      .addClass('mapboxgl-ctrl mapboxgl-ctrl-group app-map-display-elevation')
      .html(toggleButton);

    this._map = map;
    this._container = container.get(0);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed;
    return this.isDisplayed;
  }

  toggleSelector() {
    return '.app-map-display-elevation-toggle';
  }
}
