export function tracking() {
  $(function () {
    displayActivitiesTracking();
    displayBlocsTracking();
  });
}

function displayActivitiesTracking() {
  const dayTrigger = [
    '#Line_day_tracking .tp-table-tracking-more',
    '#Line_daytemplate_tracking .tp-table-tracking-more',
  ];

  displaySubElementsTracking(
    dayTrigger,
    '[id^="Line_activity_"][id$="_tracking"] .tp-table-tracking-section'
  );
}

function displayBlocsTracking() {
  const cotationTrigger = [
    '#Line_cotation_tracking .tp-table-tracking-more',
    '#Line_cotationtemplate_tracking .tp-table-tracking-more',
  ];

  displaySubElementsTracking(
    cotationTrigger,
    '[id^="Line_bloc_"][id$="_tracking"] .tp-table-tracking-section'
  );
}

function displaySubElementsTracking(parentTrigger, childrenTracking) {
  const parentSelector = parentTrigger.join(',');

  $(parentSelector).click(function () {
    $(childrenTracking).toggleClass('hidden');
    $(this).children().toggleClass('hidden');
  });
}
